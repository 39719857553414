import { combineReducers } from '@reduxjs/toolkit';
import loadingComponentReducer from '../../shared/components/LoadingComponent/services/loadingComponent.service';
import drawerReducer from '../../shared/components/drawer/services/drawer.service';
import RuntimeReducer from '../../modules/Runtime/services/human.runtime.service';
import CodeRuntimeReducer from '../../modules/Runtime/services/code.runtime.service';
import MachineIdentityReducer from '../../modules/Runtime/services/machie.runtime.service';
import ForensicReducer from '../../modules/Investigation/services/investigation.service';
import UsersIdentityInventoryReducer from '../../modules/IdentityInventory/services/users.identityInventory.service';
import RolesIdentityInventoryReducer from '../../modules/IdentityInventory/services/roles.identityInventory.service';
import GroupsIdentityInventoryReducer from '../../modules/IdentityInventory/services/groups.identityInventory.service';
import DashboardReducer from '../../modules/Dashboard/services/dashboard.service';
import RolesUsageReducer from '../../modules/Dashboard/services/rolesUsage.service';
import PoliciesReducer from '../../modules/PoliciesV2/services/policies.service';
import AwsAlertsReducer from '../../modules/AwsAlertsV2/services/awsAlerts.service';
import RemediatedAlertActionReducer from '../../modules/Actions/services/remediatedAlerts.service';
import ClosedAlertActionReducer from '../../modules/Actions/services/closedAlert.service';
import IdentityFingerprintReducer from '../../modules/IdentityFingerprint/services/identityFingerprint.service';
import ChangeManagementReducer from '../../modules/ChangeManagement/services/changeManagement.service';
import rolesReportReducer from '../../modules/IdentityFingerprint/services/rolesReport.service';
import permissionSearchReducer from '../../modules/IdentityFingerprint/services/permissionSearch.service';
import jitRoleReducer from '../../modules/PrivilegeManagement/services/jitRole.service';
import jitSSORuleReducer from '../../modules/PrivilegeManagement/services/jitSSORule.service';
import enquiryReducer from '../../modules/LandingPage/services/enquiry.service';
import profileManagementReducer from '../../modules/Settings/ProfileManagement/services/profileManagement.service';
import changePasswordReducer from '../../modules/Settings/ChangePassword/services/changePassword.service';
import OrganizationManagementReducer from '../../modules/Settings/OrganizationManagement/services/organizationManagement.service';
import settingsReducer from '../../modules/SettingsV2/services/settings.service';
import activitiesReducer from '../../modules/ActivityLog/services/activity-log.service';
import commonReducer from '../../modules/common/services/common.services';
import nonHumanIdentityInventoryReducer from '../../modules/IdentityInventory/services/nonhuman.identityInventory.service';
import investigationV2Reducer from '../../modules/InvestigationChangeManagementV2/services/investigation.service';
import changeManagementV2Reducer from '../../modules/InvestigationChangeManagementV2/services/change-management.service';
import identityFingerPrintV2Reducer from '../../modules/IdentityFingerprintV2/services/identity-fingerprint.service';
import permissionSearchV2Reducer from '../../modules/IdentityFingerprintV2/services/permission-search.service';
import rolesReportV2Reducer from '../../modules/IdentityFingerprintV2/services/roles-report.service';
import appsIdentityInventoryReducer from '../../modules/IdentityInventory/services/apps.identityInventory.service';
import { RootState } from '../configure-store';

const combinedReducer = combineReducers({
  common: commonReducer,
  loadingAPIRequest: loadingComponentReducer,
  runtime: RuntimeReducer,
  codeRuntime: CodeRuntimeReducer,
  machineRuntime: MachineIdentityReducer,
  contentDrawer: drawerReducer,
  investigation: ForensicReducer,
  usersIdentityInventory: UsersIdentityInventoryReducer,
  rolesIdentityInventory: RolesIdentityInventoryReducer,
  groupsIdentityInventory: GroupsIdentityInventoryReducer,
  nonHumanIdentityInventory: nonHumanIdentityInventoryReducer,
  dashboard: DashboardReducer,
  policies: PoliciesReducer,
  awsAlerts: AwsAlertsReducer,
  remediatedAlerts: RemediatedAlertActionReducer,
  closedAlertAction: ClosedAlertActionReducer,
  rolesUsage: RolesUsageReducer,
  identityFingerprint: IdentityFingerprintReducer,
  changeManagement: ChangeManagementReducer,
  rolesReport: rolesReportReducer,
  permissionSearch: permissionSearchReducer,
  jitRole: jitRoleReducer,
  jitSSORule: jitSSORuleReducer,
  enquiry: enquiryReducer,
  userProfile: profileManagementReducer,
  changePassword: changePasswordReducer,
  organizationManagement: OrganizationManagementReducer,
  settings: settingsReducer,
  activities: activitiesReducer,
  investigationV2: investigationV2Reducer,
  changeManagementV2: changeManagementV2Reducer,
  identityFingerprintV2: identityFingerPrintV2Reducer,
  permissionSearchV2: permissionSearchV2Reducer,
  rolesReportV2: rolesReportV2Reducer,
  appsIdentityInventory: appsIdentityInventoryReducer
});
const rootReducer = (state: any, action: any) => {
  if (action.type === 'user/logout') {
    state = undefined;
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
